<script setup lang="ts">
import { queryOptions, useQuery, useQueryClient } from "@tanstack/vue-query";
import type { PublicConference, UserConference } from "../../server/shared";
import { PublicConferencesQuery } from "../../client/service";
import { useGeoCoordinates } from "../../client/geolocation";
import { ConferenceCard } from "../conference-card";
import type { PagingObject } from "@/utils/pagination";
import { computed, defineAsyncComponent, onMounted, ref } from "vue";
import { getAddressByGeoPoint } from "../../client/nomanatim";
import MapPinIcon from "@/icons/map-pin.svg?component";
import { getCurrentLocale, loadI18n } from "@/i18n/client";
import { setDateFormatterLocale } from "@/dayjs";

const ConferencesMap = defineAsyncComponent(
	() => import("./ConferencesMap.vue"),
);

const props = defineProps<{
	conferences: PagingObject<PublicConference | UserConference>;
	options: {
		limit: number;
		type: "OFFLINE";
		near_latitude?: number;
		near_longitude?: number;
	};
}>();

const coords = useGeoCoordinates(
	props.options.near_latitude && props.options.near_longitude
		? {
				latitude: props.options.near_latitude,
				longitude: props.options.near_longitude,
			}
		: null,
);

const queryClient = useQueryClient();
queryClient.setQueryData(
	PublicConferencesQuery(props.options).queryKey,
	props.conferences,
);
const { data: conferences } = useQuery(
	queryOptions({
		...PublicConferencesQuery({
			type: props.options.type,
			limit: props.options.limit,
			near_latitude: coords.value?.latitude,
			near_longitude: coords.value?.longitude,
		}),
	}),
);

const { data: countryName } = useQuery({
	enabled: computed(() => coords.value !== null),
	queryKey: ["nomanatim", coords.value],
	queryFn: async () => {
		const result = await getAddressByGeoPoint(
			coords.value!.latitude,
			coords.value!.longitude,
		);
		return result.address.country;
	},
});

const mapData = computed<
	Array<{
		conferenceId: string;
		name: string;
		coordinates: [number, number];
	}>
>(() => {
	if (!conferences.value) return [];
	return conferences.value.items.map((conference) => {
		if (conference.type !== "OFFLINE") {
			throw new Error("Invalid conference type");
		}
		return {
			conferenceId: conference.id,
			name: conference.name,
			coordinates: [conference.latitude, conference.longitude] as [
				number,
				number,
			],
		};
	});
});

const isMounted = ref(false);
onMounted(() => (isMounted.value = true));

const locale = getCurrentLocale();
const [i18n] = await Promise.all([
	loadI18n(locale),
	setDateFormatterLocale(locale),
]);
</script>

<template>
	<template v-if="coords">
		<ConferencesMap v-if="isMounted" :data="mapData" />
		<div class="h-64 w-full animate-pulse bg-zinc-200 lg:h-[360px]" v-else />
	</template>
	<section class="relative px-6 md:px-10">
		<div class="container xl:max-w-[1440px]">
			<h2 class="py-10 text-3xl font-bold tracking-tight">
				<template v-if="coords && isMounted">
					{{ i18n.t("nearby.title") }}
					<span class="whitespace-nowrap text-indigo-500">
						<MapPinIcon class="mb-1 inline-block size-7" />
						{{ countryName }}
					</span>
				</template>
				<template v-else> {{ i18n.t("nearby.title") }} </template>
			</h2>
			<ul
				class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
			>
				<ConferenceCard
					v-for="conference of conferences?.items.slice(0, 8)"
					:key="conference.id"
					:conference="conference"
				/>
			</ul>
		</div>
	</section>
</template>
