import { onMounted, ref, toRaw, type Ref } from "vue";
import Cookies from "universal-cookie";
import { z } from "zod";
import type { AstroCookies } from "astro";

export const useGeoCoordinates = (
	defaultCoords: { latitude: number; longitude: number } | null,
): Ref<{ latitude: number; longitude: number } | null> => {
	const coords = ref<{ latitude: number; longitude: number } | null>(
		defaultCoords,
	);

	onMounted(() => {
		if (coords.value) return;
		navigator.geolocation.getCurrentPosition((position) => {
			if (
				coords.value === null ||
				coords.value.latitude !== position.coords.latitude ||
				coords.value.longitude !== position.coords.longitude
			) {
				coords.value = {
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
				};
				const cookies = new Cookies(document.cookie);
				cookies.set("geolocation", JSON.stringify(toRaw(coords.value)), {
					sameSite: "strict",
					maxAge: 60 * 60 * 24, // 1 day
				});
			}
		});
	});

	return coords;
};

export const getCoordinatesFromCookies = (cookies: AstroCookies) => {
	const geolocation = cookies.get("geolocation")?.value;
	if (!geolocation) {
		return null;
	}

	const result = z
		.object({
			latitude: z.number().refine((lat) => lat >= -90 && lat <= 90, {
				message: "Latitude must be between -90 and 90",
			}),
			longitude: z.number().refine((lng) => lng >= -180 && lng <= 180, {
				message: "Longitude must be between -180 and 180",
			}),
		})
		.safeParse(JSON.parse(geolocation));
	return result.success ? result.data : null;
};
